import React, { FC, useEffect, useState } from 'react'
import { Page, PageContainer } from '../../ui/page/page';
import './styles.scss';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Dictionary from '../../components/dictionary/dictionary';
import AppStore from '../../store/appStore';
import { Text, Title } from '../../ui/text/text';
import Button from '../../ui/button/button';
import Image from '../../ui/image/image';

interface Props {
}

const NotFound_Page: FC<Props> = observer((props: Props) => {
  const navigate = useNavigate();

  return (
    <Page className={`notFound`}>
      <PageContainer>
        <div className='notFoundBox'>
          <div className='notFoundBox__box'>
            <Title type="h1">
              404
            </Title>
            <Text type="t1">
              Страница не найдена
            </Text>
            <Button type="secondary" onClick={() => { navigate('/') }}>
              <Title type='h3'>
                На главную
              </Title>
            </Button>
          </div>
        </div>
      </PageContainer>
      <div className='botBg'>
        <Image src="ref__mainBottomBg.svg" />
      </div>
    </Page>
  )
})

export default NotFound_Page