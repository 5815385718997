import axios from "axios";

export const _SITE_URL = process.env.REACT_APP_URL;
export const _SITE_API_URL = process.env.REACT_APP_API_URL;
export const _IS_DEV = process.env.REACT_APP_IS_DEV === 'true'

const API = axios.create({
    baseURL: _SITE_API_URL
})

export default API;