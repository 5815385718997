
export const _IMAGE_CACHE = {}

export function getCl(condition?: boolean, conditionTrue?: string, conditionFalse: string = ''): string {
    return condition ? `__${conditionTrue}` : (conditionFalse ? `__${conditionFalse}` : '');
}

export function getClR(className: any): string {
    return className ? className : ''
}

export function getRawHtml(element: any) {
    return { __html: element }
}

export function getRandomInRange(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getCurrentRemValue() {
    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return rootFontSize;
}

export function shuffleArray(array: any) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
}

export async function fetchSvgMarkup(src: string, setter: (markup: any) => void, loader: (status: boolean) => void = () => { }): Promise<void> {
    try {
        const response = await fetch(src);
        const svgMarkup = await response.text();
        if (svgMarkup.includes('<svg')) {
            setter(svgMarkup)
            loader(true)
        } else {
            setter(null)
            loader(false)
        }
        // console.log('icon success', src)
    } catch (error) {
        console.error('Failed to fetch SVG markup:', error);
        setter(null)
        loader(false)
    }
}

export function loadImage(url: string) {
    return new Promise((resolve: (url: string) => void, reject) => {
        const image = new Image();

        image.onload = () => {
            //@ts-ignore
            _IMAGE_CACHE[url] = image; // Сохраняем его в кэше
            resolve(url);
        };

        image.onerror = () => {
            reject(new Error(`Failed to load image from ${url}`));
        };

        image.src = url;
    });
}



export function setCookie(name: string, value: any, options: any = {}) {
    options = {
        path: '/',
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}
export function getCookie(name: string) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
export function deleteCookie(name: string) {
    setCookie(name, "", {
        'max-age': -1
    })
}
export function anchorTo(target: string) {
    const targetBox = document.querySelector('#' + target)
    const header = document.querySelector('header');
    if (!targetBox) return
    if (!header) return

    const options: any = {
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
        offset: -(header.offsetHeight + 24)
    };

    targetBox.scrollIntoView(options);
}

export function fixWindow(type: boolean) {
    if (type) {
        setTimeout(function () {
            /* Ставим необходимую задержку, чтобы не было «конфликта» в случае, если функция фиксации вызывается сразу после расфиксации (расфиксация отменяет действия расфиксации из-за одновременного действия) */
            if (!document.body.hasAttribute('data-body-scroll-fix')) {
                // Получаем позицию прокрутки
                let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
                // Ставим нужные стили
                document.body.setAttribute('data-body-scroll-fix', scrollPosition.toString()); // Cтавим атрибут со значением прокрутки
                document.body.style.overflow = 'hidden';
                document.body.style.position = 'fixed';
                document.body.style.top = '-' + scrollPosition + 'px';
                document.body.style.left = '0';
                document.body.style.width = '100%';
            }
        }, 15);
    } else {
        if (document.body.hasAttribute('data-body-scroll-fix')) {
            // Получаем позицию прокрутки из атрибута
            let scrollPosition = document.body.getAttribute('data-body-scroll-fix');
            // Удаляем атрибут
            document.body.removeAttribute('data-body-scroll-fix');
            // Удаляем ненужные стили
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.left = '';
            document.body.style.width = '';
            // Прокручиваем страницу на полученное из атрибута значение
            if (scrollPosition !== null) {
                window.scroll(0, parseInt(scrollPosition));
            }
        }
    }
}

function fallbackCopyTextToClipboard(text: string, cb: () => void) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
        cb()
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}
export function copyTextToClipboard(text: string, cb: () => void) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text, cb);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        cb()
        // console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
    });
}


export function targetYM(t: string) {
    // @ts-ignore
    if (typeof window.ym === 'function') window.ym(94727854, 'reachGoal', t)
}
