import React, { FC, useEffect, useState } from 'react'
import { Page, PageContainer } from '../../ui/page/page';
import Image from '../../ui/image/image';
import { Text, Title } from '../../ui/text/text';
import './styles.scss';
import { observer } from 'mobx-react-lite';
import TopicItem from '../../components/choiceItem/choiceItem';
import Button from '../../ui/button/button';
import { useNavigate } from 'react-router-dom';
import { getCl } from '../../helper';
import GameStore from '../../store/gameStore';
import AppStore from '../../store/appStore';

interface Props {
}

const Choice_Page: FC<Props> = observer((props: Props) => {
  const navigate = useNavigate();

  const setGame = () => {
    GameStore.reset()
    setTimeout(() => {
      navigate('/game')
    }, 200)
  }

  return (
    <Page className={`choice`}>
      <PageContainer>
        <Text type={'t2'} align="center" className='mainText'>
          Выберите категорию слов,<br />которые будете отгадывать:
        </Text>
        <div className='list'>
          {AppStore.dicts.map(dict => (
            <div className='item' key={dict.id}>
              <TopicItem {...dict} onClick={() => {
                AppStore.selectDict(dict.id)
                setGame()
              }}
              />
            </div>
          ))}
        </div>
        {/* <div className='actions'>
          <Button type='primary' disabled={!AppStore.data.selectedDictId} onClick={setGame}
          />
        </div> */}
      </PageContainer>
      <div className='botBg'>
        <Image src="ref__mainBottomBg.svg" />
      </div>
    </Page>
  )
})

export default Choice_Page