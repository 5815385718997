
import React, { FC, useEffect, useState } from 'react'
import './styles.scss';
import { getCl, getRawHtml, targetYM } from '../../helper';
import Icon from '../../ui/icon/icon';
import Button from '../../ui/button/button';
import AppStore from '../../store/appStore';

interface Props {
    icon: string,
    title?: string,
    text?: string,
    isActive: boolean,
    isEmpty?: boolean,
    setActive: (v: boolean) => void,
    action?: () => void,
    download?: string
}

const GameMess: FC<Props> = (props: Props) => {

    const [inited, setInited] = useState<boolean>(false)
    const [title, setTitle] = useState<string | undefined>('')
    const [text, setText] = useState<string | undefined>('')

    useEffect(() => {
        setInited(false)
        setTimeout(() => {
            setTitle(props.title)
            setText(props.text)
            setTimeout(() => {
                setInited(true)
            }, 10)
        }, 200)
    }, [props.title, props.text])

    return (
        <div className={`gameMess ${getCl(props.isActive, 'active')} ${getCl(inited, 'inited')}`}>
            <div className='gameMess__outer'>
                <div className='gameMess__mark'>
                    <Icon name='mark' />
                </div>
                <div className='gameMess__body'>
                    {props.isEmpty ?
                        <div className='gameMess__text'>Здесь пока ничего нет</div>
                        :
                        <>
                            <div className='gameMess__scroller'>
                                <div className='gameMess__inner'>
                                    <div className='gameMess__icon'>
                                        <Icon name={props.icon} />
                                    </div>
                                    <div className='gameMess__title'>
                                        {title}
                                    </div>
                                    <div className='gameMess__text' dangerouslySetInnerHTML={getRawHtml(text)}></div>
                                </div>
                            </div>
                            <div className='gameMess__actions'>
                                {props.download ?
                                    <Button type='download' download={props.download}></Button>
                                    :
                                    <Button type='share' onClick={() => {
                                        targetYM('click_share')
                                        AppStore.toggleUIShareActivity(true)
                                    }}></Button>
                                }
                                {typeof props.action === 'function' &&
                                    <Button type='primary' onClick={props.action}></Button>
                                }
                            </div>
                        </>}
                </div>
            </div>
        </div>
    )
}

export default GameMess;