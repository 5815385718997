import React, { FC, useEffect, useRef, useState } from 'react'
import './styles.scss';
import { Text, Title } from '../../ui/text/text';
import { getCl, getCurrentRemValue, getRandomInRange, getRawHtml, shuffleArray, targetYM } from '../../helper';
import Image from '../../ui/image/image';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { observer } from 'mobx-react-lite';
import Drop from '../dragNdrop/dragNdrop';
import Icon from '../../ui/icon/icon';
import GameMess from './gameMess';
import GameTip from './gameTip';
import GameStore, { IChar, IPlace } from '../../store/gameStore';
import AppStore from '../../store/appStore';


interface Ipointer {
  x: number,
  oldX: number,
  distanceX: number,
  prevX: number,
  y: number,
  oldY: number,
  distanceY: number,
  prevY: number,
  isDragging: boolean,
  placeRef: Element | null,
  placeRefOld: Element | null,
  targetRef: HTMLDivElement | null,
  placeId: number | null,
  targetId: number | null,
  oldTargetId: number | null,
  oldPlaceId: number | null,
  inStock: boolean
  eTarget: Element | null,
  htmlElement: HTMLElement | null
  bodyElement: HTMLBodyElement | null
}

const pointer: Ipointer = {
  x: 0,
  oldX: 0,
  distanceX: 0,
  prevX: 0,
  y: 0,
  oldY: 0,
  distanceY: 0,
  prevY: 0,
  isDragging: false,
  placeRef: null,
  placeRefOld: null,
  targetRef: null,
  placeId: null,
  targetId: null,
  oldTargetId: null,
  oldPlaceId: null,
  inStock: false,
  eTarget: null,
  htmlElement: document.querySelector('html'),
  bodyElement: document.querySelector('body')
}

interface Props {
  word?: string,
  activity: boolean,
}

const GameBox: FC<Props> = observer((props: Props) => {
  const stockRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<HTMLDivElement>(null)
  const gameBoxRef = useRef<HTMLDivElement>(null)
  const [gameMessActive, setGameMessActive] = useState<boolean>(false);
  const [gameTipActive, setGameTipActive] = useState<boolean>(false);
  const [learningLevel, setLearningLevel] = useState<number>(AppStore.ui.learningLevel)

  useEffect(() => {
    if (stockRef.current) {
      GameStore.updateChars({
        width: stockRef.current.clientWidth,
        height: stockRef.current.clientHeight,
      }, {
        width: getCurrentRemValue() * 6,
        height: getCurrentRemValue() * 6
      })
    }
    window.addEventListener('pointermove', onPointerMove)
    window.addEventListener('pointerup', onPointerUp)
    return () => {
      window.removeEventListener('pointermove', onPointerMove)
      window.removeEventListener('pointerup', onPointerUp)
    }
  }, [GameStore.word])

  useEffect(() => {
    setGameTipActive(!AppStore.data.learningPassed)
  }, [AppStore.data.learningPassed])
  useEffect(() => {
    if (!AppStore.data.learningPassed) {
      setLearningLevel(AppStore.ui.learningLevel)
    } else {
      setLearningLevel(0)
    }
  }, [AppStore.ui.learningLevel])

  useEffect(() => {
    if (GameStore.wordCorrect && gridRef.current && stockRef.current && gameBoxRef.current) {
      let gameBoxPos = gameBoxRef.current.getBoundingClientRect();
      let stockPos = stockRef.current.getBoundingClientRect();
      let gridPos = {
        x: stockPos.x - gameBoxPos.x,
        y: stockPos.y - gameBoxPos.y,
        scale: (stockPos.width * .96) / gridRef.current.clientWidth
      }
      // debugger
      setTimeout(() => {
        if (gridRef.current) {
          gridRef.current.style.transform = `translate(${gridPos.x}px, ${gridPos.y}px) scale(${gridPos.scale})`
          // gridRef.current.style.scale = `${gridPos.scale}`
          gridRef.current.style.transformOrigin = `left top`
        }
        setGameMessActive(true)
      }, GameStore.word.length * 100 + 600)
      setTimeout(() => {
      }, GameStore.word.length * 100 + 600)
    } else {
      setGameMessActive(false)
      if (gridRef.current) {
        gridRef.current.style.transform = `translate(0, 0) scale(1)`
        gridRef.current.style.transformOrigin = `center center`
      }
    }
  }, [GameStore.wordCorrect])

  function onPointerMove(e: PointerEvent) {
    if (!pointer.isDragging || pointer.targetRef === null) return
    pointer.x = e.clientX;
    pointer.y = e.clientY;
    pointer.distanceX = pointer.x - pointer.oldX;
    pointer.distanceY = pointer.y - pointer.oldY;

    pointer.targetRef.style.transform = `translate(${pointer.distanceX}px, ${pointer.distanceY}px)`

    pointer.eTarget = document.elementFromPoint(e.clientX - pointer.prevX, e.clientY - pointer.prevY)
    // console.log({ el: pointer.eTarget, par: pointer.eTarget?.parentElement })
    let target;

    if (pointer.eTarget?.classList.contains('stock') || pointer.eTarget?.closest('.stock')?.classList.contains('stock')) {
      pointer.inStock = true
      stockRef.current?.classList.add('__highlight')
    } else {
      pointer.inStock = false
      stockRef.current?.classList.remove('__highlight')
    }

    if (pointer.eTarget?.classList.contains('place')) {
      target = pointer.eTarget
    }
    if (pointer.eTarget?.parentElement?.classList.contains('place')) {
      target = pointer.eTarget?.parentElement
    }
    removePlacesHighlight()
    if (target) {
      let attr = target.getAttribute('data-place-id')
      if (attr) {
        let placeId = parseInt(attr)
        if (placeId !== null) {
          onPlacePointerEnter(placeId, target)
        }
      }
    } else {
      onPlacePointerLeave()
    }
  }

  function onPointerUp() {
    pointer.isDragging = false
    if (pointer.htmlElement) pointer.htmlElement.style.cursor = 'unset'
    // if (pointer.bodyElement) pointer.bodyElement.style.touchAction = 'pan-y'
    if (pointer.targetRef === null) return

    pointer.targetRef.classList.remove('__dragging')
    pointer.placeRef?.classList.remove('__highlight')
    stockRef.current?.classList.remove('__highlight')

    if (pointer.placeId === null) {
      if (pointer.inStock) {
        console.log(pointer.placeRef, pointer.oldPlaceId)
        if (!pointer.placeRef) {
          pointer.targetRef.style.transform = `none`
        }
        if (!pointer.oldPlaceId) {
          pointer.targetRef.style.transform = `none`
          pointer.targetRef.classList.add('__dragging')
          GameStore.updateCharPosition(pointer.targetId, pointer.distanceX, pointer.distanceY, () => {
            pointer.targetRef?.classList.remove('__dragging')
          })
        }
        pointer.oldPlaceId = null
        pointer.oldTargetId = null
        let targetId = pointer.targetId;
        let placeRect = pointer.placeRefOld?.getBoundingClientRect();
        let stockRect = stockRef.current?.getBoundingClientRect();
        let char = GameStore.getCharById(targetId)

        if (stockRect && placeRect && char) {
          let x = (stockRect.left - placeRect.left + char.x)
          let y = (stockRect.top - placeRect.top + char.y)
          console.log(x, stockRect, placeRect)
          pointer.targetRef.style.transform = `translate(${x}px, ${y}px)`
          // pointer.targetRef.style.translate = ``
        }
        setTimeout(() => {
          GameStore.updatePlace(null, targetId)
        }, 400)
      } else {
        pointer.targetRef.style.transform = `none`
      }
    } else {
      let placeRect = pointer.placeRef?.getBoundingClientRect();
      let targetRect = pointer.targetRef.getBoundingClientRect();
      let placeId = pointer.placeId
      let targetId = pointer.targetId

      if (placeRect && targetRect) {
        let x = -(targetRect.left + -pointer.distanceX - placeRect.left)
        let y = -(targetRect.top + -pointer.distanceY - placeRect.top)
        pointer.targetRef.style.transform = `translate(${x}px, ${y}px)`
      }

      if ((pointer.oldTargetId !== pointer.targetId || pointer.oldPlaceId !== pointer.placeId)) {
        console.log(pointer.oldPlaceId, pointer.placeId)
        if (pointer.oldTargetId !== pointer.targetId && pointer.oldTargetId !== null) {
          pointer.placeRef?.classList.add('__changing')
        }
        pointer.oldTargetId = targetId;
        pointer.oldPlaceId = placeId;
        setTimeout(() => {
          GameStore.updatePlace(placeId, targetId)
        }, 400)
      }
    }

    pointer.placeId = null;
    pointer.targetId = null;
    pointer.targetRef = null;
    pointer.placeRefOld = null;
    pointer.oldPlaceId = null
  }

  function onPlacePointerEnter(id: number, ref: Element) {
    // console.log('enter', id)
    pointer.placeId = id;
    pointer.placeRef = ref;
    pointer.placeRef?.classList.add('__highlight')
  }
  function onPlacePointerLeave() {
    pointer.placeId = null;
    pointer.placeRef = null;
  }

  function removePlacesHighlight() {
    let places = document.querySelectorAll('.place');
    places.forEach(el => el.classList.remove('__highlight'))
  }

  function onCharPointerDown(e: React.PointerEvent<HTMLDivElement>, id: number, ref: HTMLDivElement) {
    if (pointer.htmlElement) pointer.htmlElement.style.cursor = 'grabbing'
    // if (pointer.bodyElement) pointer.bodyElement.style.touchAction = 'none'
    pointer.oldX = e.clientX;
    pointer.oldY = e.clientY;
    pointer.isDragging = true;
    pointer.targetRef = ref
    pointer.targetId = id
    pointer.targetRef.classList.add('__dragging')

    if (e.currentTarget.closest('.place')) {
      pointer.placeRefOld = e.currentTarget.closest('.place')
      let attr = pointer.placeRefOld?.getAttribute('data-place-id')
      if (attr) {
        pointer.oldPlaceId = parseInt(attr)
      }
    }

    const el = e.currentTarget;
    const rect = el.getBoundingClientRect();
    if (rect) {
      pointer.prevX = pointer.oldX - rect.left - rect.width / 2;
      pointer.prevY = pointer.oldY - rect.top - rect.height / 2;
    }
  }

  function getTipContainer(type: string) {
    let container =
      <div className='gameBox__gameTipContainer'>
        <GameTip
          icon={'tip_hand'}
          isActive={gameTipActive}
          setActive={(val: boolean) => {
            setGameTipActive(val)
            AppStore.setLearningHide()
          }}
        />
      </div>
    if (window.innerWidth > 820 && type === 'desk') {
      return container
    }
    if (window.innerWidth < 820 && type === 'tab') {
      return container
    }
  }
  function getMessContainer(type: string) {
    let container =
      <div className='gameBox__gameMessContainer'>
        <GameMess
          icon={'tip_hand'}
          title={AppStore.data.selectedWord?.word}
          text={AppStore.data.selectedWord?.description}
          isActive={gameMessActive}
          setActive={setGameMessActive}
          action={() => {
            targetYM('click_win_dalee')
            AppStore.nextStep();
          }}
        />
      </div>
    if (window.innerWidth > 820 && type === 'desk') {
      return container
    }
    if (window.innerWidth < 820 && type === 'tab') {
      return container
    }
  }

  const classlist = [
    getCl(GameStore.wordCorrect, 'correct'),
    getCl(gameMessActive, 'correct_done'),
    getCl(GameStore.loading, 'loading'),
    getCl(!!learningLevel, 'learn_' + learningLevel),
    getCl(props.activity, 'active')
  ].join(' ')
  useEffect(() => {
    console.log(AppStore.ui.tipActivity,'AppStore.ui.tipActivity')
  }, [AppStore.ui.tipActivity])

  return (
    <div className={`gameBox ${classlist}`} ref={gameBoxRef}>
      <div className='gameBox__col __main'>
        <div className='gameBox__grid grid' ref={gridRef}>
          {GameStore.places.map((place, i) => {
            // console.log(place.id, place.charId)
            return (
              <GamePlace
                {...place}
                visibleChar={learningLevel === 3}
                key={'place__' + i}
                onCharPointerDown={onCharPointerDown}
              />
            )
          })}
        </div>
        <div className={`gameMainTip ${getCl(AppStore.ui.tipActivity, 'active')}`}>
          <div className='gameMainTip__head'>
            <Title type="h3">Подсказка</Title>
            <div className='gameMainTip__close' onClick={() => { AppStore.toggleUITipActivity(false) }}>
              <Icon name="close" />
            </div>
          </div>
          <div className='gameMainTip__body'>
            <Text type={"t2"}>
              <span dangerouslySetInnerHTML={getRawHtml(AppStore.data.selectedWord?.hint)} />
            </Text>
          </div>
        </div>

        {getMessContainer('desk')}
        {getTipContainer('desk')}

      </div>
      <div className='gameBox__col __stock'>
        <div className='gameBox__stock'>
          <div className='gameBox__stockInner stock' ref={stockRef}>
            {GameStore.chars.map((char, i) => {
              // console.log(char.id, char.placeId)
              if (char.placed) return
              return (
                <div className='gameBox__stockItem' style={{ left: char.x, top: char.y }} key={"stockItem__" + i}>
                  <GameChar {...char} onPointerDown={onCharPointerDown} />
                </div>
              )
            })}
          </div>
          <div className='gameBox__stock_image'>
            <Image src={GameStore._word?.images[0]} />
          </div>
        </div>
        {getTipContainer('tab')}
      </div>
      {getMessContainer('tab')}
    </div>
  )
})

interface GamePlaceProps extends IPlace {
  visibleChar?: boolean,
  onCharPointerDown: (e: React.PointerEvent<HTMLDivElement>, id: number, ref: HTMLDivElement) => void,
}

const GamePlace: FC<GamePlaceProps> = (props: GamePlaceProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [char, setChar] = useState<IChar | undefined>(undefined)

  useEffect(() => {
    ref.current?.classList.remove('__changing')
    setTimeout(() => {
      let char = GameStore.getCharById(props.charId)
      setChar(char);
    }, 10)

  }, [props.charId])
  return (
    <div
      className={`place gameBox__gridItem ${getCl(props.blocked, 'blocked')} ${getCl(props.correct, 'correct')}`}
      ref={ref}
      data-place-id={props.id}
    >
      {(!props.charId && props.visibleChar) &&
        <div className='exampleChar'>
          <svg width="134" height="134" viewBox="0 0 134 134" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="67.4004" cy="67.4004" r="54" fill="#fff" />
          </svg>
          <span>
            {props.correctLetter}
          </span>
        </div>
      }
      {char && <GameChar {...char} onPointerDown={props.onCharPointerDown} />}
    </div>
  )
}

interface GameCharProps extends IChar {
  onPointerDown: (e: React.PointerEvent<HTMLDivElement>, id: number, ref: HTMLDivElement) => void,
}

const GameChar: FC<GameCharProps> = observer((props: GameCharProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    // setLoaded(true)
    setTimeout(() => { setLoaded(true) }, 10)
  }, [])

  function onPointerDown(e: React.PointerEvent<HTMLDivElement>) {
    // console.log("TEST")
    if (ref.current) {
      props.onPointerDown(e, props.id, ref.current)
    }
  }
  return (
    <div
      className={`gameBox__char ${getCl(true, props.color)} ${getCl(loaded, 'loaded')}`}
      onPointerDown={onPointerDown}
      // onMouseDown={() => {console.log("TESTSSS")}}
      ref={ref}
    >
      <div className='image'>
        <div className='image__svg'>
          <svg width="134" height="134" viewBox="0 0 134 134" fill="none" xmlns="http://www.w3.org/2000/svg">
            {props.shape === 'letter-1' &&
              <circle cx="67.4004" cy="67.4004" r="54" fill="#fff" />
            }
            {props.shape === 'letter-2' &&
              <path d="M63.3096 9.61848C65.7848 8.18942 68.8344 8.18942 71.3096 9.61848L115.271 34.9997C117.746 36.4287 119.271 39.0698 119.271 41.9279V92.6903C119.271 95.5484 117.746 98.1894 115.271 99.6185L71.3096 125C68.8344 126.429 65.7848 126.429 63.3096 125L19.348 99.6185C16.8728 98.1894 15.348 95.5484 15.348 92.6903V41.9279C15.348 39.0698 16.8728 36.4287 19.348 34.9997L63.3096 9.61848Z" fill="#fff" />
            }
            {props.shape === 'letter-3' &&
              <path d="M61.3431 5.65686C64.4673 2.53267 69.5327 2.53266 72.6569 5.65685L128.343 61.3431C131.467 64.4673 131.467 69.5327 128.343 72.6569L72.6569 128.343C69.5327 131.467 64.4673 131.467 61.3431 128.343L5.65686 72.6569C2.53267 69.5327 2.53266 64.4673 5.65685 61.3431L61.3431 5.65686Z" fill="#fff" />
            }
            {props.shape === 'letter-4' &&
              <path d="M62.6073 10.7255C65.4112 8.68834 69.208 8.68834 72.0119 10.7255L119.671 45.3517C122.475 47.3888 123.648 50.9997 122.577 54.2959L104.373 110.322C103.302 113.618 100.23 115.85 96.7644 115.85H37.8548C34.389 115.85 31.3173 113.618 30.2463 110.322L12.0423 54.2959C10.9713 50.9997 12.1446 47.3888 14.9485 45.3517L62.6073 10.7255Z" fill="#ffffff" />
            }
            {props.shape === 'letter-5' &&
              <rect x="11.7246" y="13.3999" width="108" height="108" rx="8" fill="#ffffff" />
            }
          </svg>
        </div>
      </div>
      {/* <Image src={props.shape} /> */}
      <Title type='h2' className='gameBox__char_text'>
        {props.letter}
      </Title>
    </div>
  )
})

export default GameBox;