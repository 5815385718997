import React, { FC, JSX, HTMLAttributes, ReactNode } from 'react';
import './styles.scss';
import { getCl, getClR } from '../../helper';

interface TextProps {
    children: ReactNode
    className?: string,
    align?: 'center' | 'left' | 'right',
    type: 'tNumbers' | 't1' | 't2' | 't3',
}

export const Text: FC<TextProps> = (props: TextProps) => {
    return (
        <div className={`text ${getClR(props.className)} ${props.type} ${getCl(!!props.align, 'align_' + props.align)}`}>
            {props.children}
        </div>
    )
}

interface TitleProps extends React.HTMLProps<HTMLDivElement> {
    children: ReactNode
    className?: string,
    align?: 'center' | 'left' | 'right',
    type: 'h1' | 'h2' | 'h3' | 'h4',
    tag?: keyof JSX.IntrinsicElements
}

export const Title: FC<TitleProps> = (props: TitleProps) => {
    const TagName = props.tag || 'div';
    return (
        <TagName className={`title ${getClR(props.className)} ${props.type} ${getCl(!!props.align, 'align_' + props.align)}`}>
            {props.children}
        </TagName>
    )
}