import React, { FC, useEffect, useState } from 'react'
import './styles.scss';
import GameMess from '../gameBox/gameMess';
import { Text, Title } from '../../ui/text/text';
import Image from '../../ui/image/image';
import { anchorTo, getCl } from '../../helper';
import AppStore, { IWord } from '../../store/appStore';
import { observer } from 'mobx-react-lite';

interface Props {

}

const Dictionary: FC<Props> = observer((props: Props) => {
    return (
        <div className={`dict`} id="id_Dict">
            <div className={`dict__col`} >
                <div className={`dict__message`} >
                    <GameMess
                        icon={'tip_hand'}
                        title={AppStore.data.selectedDictionaryWord?.word}
                        text={AppStore.data.selectedDictionaryWord?.description}
                        isEmpty={AppStore.data.selectedDictionaryWord === null}
                        isActive={true}
                        setActive={function (v: boolean): void {
                            throw new Error('Function not implemented.');
                        }}
                    />
                </div>
            </div>
            <div className={`dict__col`} >
                <div className='dict__scroller'>
                    <div className='dict__list'>
                        {AppStore.getSelectedDict()?.levels.map((lvl, i) => {
                            return (
                                <DictionaryItem
                                    key={'dictItem__' + i}
                                    index={i}
                                    level={lvl.number}
                                    current={AppStore.getLevelCurValue(lvl)}
                                    total={AppStore.getLevelCurTotal(lvl)}
                                    items={AppStore.getLevelPullWords(lvl)}
                                />
                                // <div>{lvl.level}: {lvl.words.length}</div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
})

interface DictionaryItemProps {
    index: number,
    level: number,
    total: number,
    current: number,
    items: IWord[]
}

const DictionaryItem: FC<DictionaryItemProps> = observer((props: DictionaryItemProps) => {
    return (
        <div className='dictItem'>
            <div className='dictItem__title'>
                <Title type="h4">
                    {props.level} Уровень: {props.current}/{props.total}
                </Title>
            </div>
            <div className='dictItem__list'>
                {props.items.map((item, i) => (
                    <DictionaryItemListItem
                        {...item}
                        key={'dictItem__' + props.index + "_" + i}
                        onClick={() => {
                            if(item.completed){
                                AppStore.selectDictionaryWord(item)
                                anchorTo('root')
                            }
                        }}
                    />
                ))}
            </div>
        </div>
    )
})

interface DictionaryItemListItemProps extends IWord {
    onClick: () => void;
}

const DictionaryItemListItem: FC<DictionaryItemListItemProps> = observer((props: DictionaryItemListItemProps) => {
    return (
        <div className={`dictInnerItem ${getCl(props.id === AppStore.data.selectedDictionaryWord?.id, 'active')}`} onClick={props.onClick}>
            <div className='dictInnerItem__image'>
                {!props.completed &&
                    <Image src={'ref__emptyWord.svg'} />
                }
                {(props.dictionary_image && props.completed) &&
                    <Image src={props.dictionary_image} />
                }
            </div>
            <div className='dictInnerItem__word'>
                <Title type="h4">
                    {props.completed ? props.word : '-'}
                </Title>
            </div>
        </div>
    )
})

export default Dictionary;