
import React, { FC, useEffect, useState } from 'react'
import './styles.scss';
import { _IS_DEV } from '../../API';

interface Props {
}

const Analitycs: FC<Props> = (props: Props) => {
    const [wnd, setWnd] = useState<any>({})
    const build = '0.9.16'
    useEffect(() => {
        resize();
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])

    const resize = () => {
        setWnd({
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            devicePixelRatio: window.devicePixelRatio,
        })
    }
    // console.log(_IS_DEV, process.env.REACT_APP_IS_DEV)
    return (
        <div style={{display: _IS_DEV ? '' : 'none'}}>
            <div className='nonRelease'>
                Non-release version
            </div>
            <div className='analitycs' >
                {wnd.innerWidth}px x {wnd.innerHeight}px  | 
                 dPRatio: {wnd.devicePixelRatio}  |
                 build: {build}<br />
                 UA: {navigator.userAgent}
                <div className='grad'></div>
            </div>
        </div>
    )
}

export default Analitycs;