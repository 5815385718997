import { makeAutoObservable, observable } from "mobx";
import { getRandomInRange, shuffleArray } from "../helper";
import { getRandomPositions } from "../gameHelper";
import AppStore, { IWord } from "./appStore";

export interface IChar {
    id: number,
    order: number,
    letter: string,
    placeId: number | null,
    placed: boolean,
    x: number,
    y: number,
    color: string,
    shape: string,
}
export interface IPlace {
    id: number,
    charId: number | null,
    blocked: boolean,
    correct: boolean,
    correctLetter: string,
}

export interface IGameStore {
    _word: IWord,
    word: string,
    wordCorrect: boolean,
    chars: IChar[],
    charsElement: { width: number, height: number },
    charsBox: { width: number, height: number },
    places: IPlace[],
    charsInited: boolean,
    updateSeed: number | null,
    loading: boolean,
    ready: boolean,
    // _charColors: string[]
}

class _GameStore implements IGameStore {
    private _charColors = ['white', 'violet', 'pink', 'orange', 'green', 'lightviolet']
    _word = {
        images: [] as string[]
    } as IWord
    word = '';
    wordCorrect = false;
    chars = [] as IChar[];
    places = [] as IPlace[];
    charsInited = false;
    charsElement = { width: 0, height: 0 };
    charsBox = { width: 0, height: 0 };
    updateSeed = null as number | null
    loading = true;
    ready = false;
    constructor() {
        makeAutoObservable(this)
        // this.setWord('Фитосексуальностьядренбатон')
        // this.setWord('Фитосексуальностьядренбатон')
    }

    setWord = (word: IWord) => {
        this._word = word;
        this.word = word.word;
        // this.word = 'Лалаленд'
        this.charsInited = false;
        this.initChars()
    }

    initChars = () => {
        let i = 0;
        let arr: IChar[] = [];
        let arrPlaces: IPlace[] = [];
        this.word.split('').map(letter => {
            i++;
            let letterLowerCase = letter.toLowerCase()
            let char: IChar = {
                id: i,
                order: i,
                letter: letterLowerCase,
                placeId: null,
                placed: false,
                x: 0,
                y: 0,
                color: '',
                shape: ''
            }
            arr.push(char)
            arrPlaces.push({ id: i, charId: null, blocked: false, correct: false, correctLetter: letterLowerCase })
        })

        this.setChars(arr)
        this.setPlaces(arrPlaces)
    }
    setChars = (chars: IChar[]) => {
        this.chars = chars
    }
    setPlaces = (places: IPlace[]) => {
        this.places = places
    }
    updateChars = (box: any, element: any) => {
        // console.log('updateChars')
        const positions: { x: number; y: number }[] = [];
        let newArr = this.chars.map(char => {
            let x, y;
            let i = 0
            try {
                let pos = getRandomPositions(positions, box, element, i)
                x = pos.x;
                y = pos.y;
            } catch {
                x = getRandomInRange(0, (box.width - element.width));
                y = getRandomInRange(0, (box.height - element.height));
            }

            let shape = 'letter-' + getRandomInRange(1, 5);
            let color = this._charColors[getRandomInRange(0, this._charColors.length - 1)];

            let newChar: IChar = { ...char, x, y, color, shape }
            // console.log({ x })
            return newChar;
        })
        this.setChars(newArr)
        this.charsInited = true
        this.charsBox = box;
        this.charsElement = element;
        setTimeout(() => {
            this.loading = false
            this.ready = true
        }, 400)

    }

    updatePlace = (placeId: number | null, charId: number | null) => {
        let char = this.chars.filter(char => char.id === charId)[0];
        let oldChar = this.chars.filter(char => char.placeId === placeId)[0];
        // let place = this.places.filter(place => place.id === placeId)[0];
        char.placeId = placeId;
        console.log(char, oldChar, placeId, charId)
        if (oldChar) {
            // console.log(char.id, oldChar.id)
            if (oldChar.id !== char.id) {
                oldChar.placeId = null
            }
        }

        // char.placed = true;
        // place.charId = charId;

        this.checkCharPlace();

        if(AppStore.ui.learningLevel === 1){
            AppStore.setNextLearningLevel()
        }
    }

    checkCharPlace = () => {
        let i = 0
        this.places.map(place => {
            place.charId = null
            place.correct = false
        })

        this.chars.map(char => {
            if (char.placeId !== null) {
                let place = this.places.filter(place => place.id === char.placeId)[0];
                if (place) {
                    place.charId = char.id
                    char.placed = true
                    if (place.correctLetter === char.letter) {
                        place.correct = true
                        i++;
                    } else {
                        place.correct = false
                    }
                } else {
                    char.placeId = null
                    char.placed = false
                }
            } else {
                char.placed = false
            }
        })
        if (this.chars.length === i) {
            this.checkDone();
        }
    }

    getCharById = (id: number | null) => {
        if (id !== null) {
            let char = this.chars.filter(char => char.id === id)[0];
            return char;
        } else {
            return undefined
        }
    }

    checkDone = () => {
        console.log("CHECK DONE")
        this.wordCorrect = false
        let law = true;
        let wordArr: { id: number, char: string }[] = []
        this.places.map(el => { law = el.charId !== null })

        if (law) {
            this.chars.map(el => {
                law = el.placeId !== null
                if (law && el.placeId) {
                    wordArr.push({ id: el.placeId, char: el.letter })
                }
            })
        }

        if (law && wordArr) {
            let word = ''
            wordArr.sort((a, b) => a.id - b.id);
            wordArr.map(el => word += el.char)

            // console.log(word, wordArr, this.word, word === this.word)
            if (this.word.toLowerCase() === word.toLowerCase()) {
                this.setDone()
            } else {
            }
        }
    }

    updateCharPosition = (id: number | null, x: number, y: number, cb: () => void) => {
        if (id === null) {
            return
        }
        this.chars.map(char => {
            if (char.id === id) {
                char.x = char.x + x;
                char.y = char.y + y;
                if (char.x < 0) {
                    char.x = 0
                }
                if (char.y < 0) {
                    char.y = 0
                }
                if (char.x > this.charsBox.width - this.charsElement.width) {
                    char.x = this.charsBox.width - this.charsElement.width
                }
                if (char.y > this.charsBox.height - this.charsElement.height) {
                    char.y = this.charsBox.height - this.charsElement.height
                }
            }
        })
        cb();
    }

    reset = () => {
        this.loading = true
        this.ready = false;
        setTimeout(() => {
            this.chars.map(char => {
                char.placeId = null
                char.placed = false
            })
            this.places.map(place => {
                place.charId = null
                place.correct = false;
                place.blocked = false
            })
            this.wordCorrect = false;
        }, 400)
    }

    setDone = () => {
        AppStore.setWordComplete(this._word.id)
        setTimeout(() => {
            this.wordCorrect = true
        }, 200)
    }

    useTip = () => {
        if (!this.ready) return false
        let freePlace = this.places.filter(place => place.blocked === false && place.correct === false)[0]
        if (!freePlace) return false

        let used = false;
        let correctChars = this.chars.filter(char => char.letter === freePlace.correctLetter)
        correctChars.map(char => {
            console.log(char, char.placeId)
            if (char.placeId === null && !used) {
                this.updatePlace(freePlace.id, char.id)
                freePlace.blocked = true
                used = true;
            }
        })
        if (!used) {
            correctChars.map(char => {
                if(!used){
                    this.updatePlace(freePlace.id, char.id)
                    freePlace.blocked = true
                    used = true;
                }
            })
        }
        return true
        // freePlace.blocked = true;
    }
}

const GameStore = new _GameStore()

export default GameStore;
