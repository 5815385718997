import { FC, ReactNode } from "react";
import './styles.scss';
import { getCl } from "../../helper";
import Icon from "../icon/icon";
import Image from "../image/image";

interface Props {
    type: 'primary' | 'secondary' | 'tretiary' | 'share' | 'download',
    children?: ReactNode,
    icon?: string,
    loading?: boolean,
    onClick?: () => void,
    disabled?: boolean,
    classList?: string,
    download?: string,
}

const Button: FC<Props> = (props: Props) => {
    const className: string = `btn ${getCl(true, props.type)} ${getCl(props.disabled, 'disabled')} ${props.classList ? props.classList : ''} ${getCl(!props.children, 'icon')} ${getCl(props.loading, 'loading')}`;
    if (props.type === 'primary') {
        return (
            <div className="btn__wrapper">
                <button className={className} onClick={props.onClick}>
                    <Image src="ref__btn_primary_text.svg" />
                </button>
            </div>
        )
    } else if (props.type === 'share') {
        return (
            <button className={className} onClick={props.onClick}>
                <Icon name="share" />
            </button>
        )
    } else if (props.type === 'download') {
        return (
            <a className={className} onClick={props.onClick} href={props.download} download={true}>
                <Icon name="download" />
            </a>
        )
    } else {
        return (
            <button className={className} onClick={props.onClick}>
                {props.icon &&
                    <Icon name={props.icon} />
                }
                {props.children}
            </button>
        )
    }
}

export default Button;