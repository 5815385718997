import { makeAutoObservable, observable } from "mobx";
import GameStore from "./gameStore";
import { getRandomInRange, targetYM } from "../helper";
import { _IS_DEV } from "../API";

export interface IUI {
    menuActivity: boolean,
    gameActivity: boolean,
    shareActivity: boolean,
    tipActivity: boolean,
    disableReset: boolean,
    learningLevel: number,
    canUse: boolean,
    level: {
        current: number,
        total: number
    },
    term: {
        current: number,
        total: number
    },
    tips: number
}

export interface ILevel {
    id: number,
    dId: number,
    number: number,
    words: IWord[],
    pull: number[],
    completed?: boolean,
    selected?: boolean
}
export interface IWord {
    id: number,
    originalId: number,
    word: string,
    level: number,
    lId: number,
    dId: number,
    description: string,
    share: string,
    hint: string,
    link: string,
    images: string[],
    dictionary_image: string,
    completed?: boolean,
    selected?: boolean
}

export interface IDict {
    id: number,
    title: string,
    description: string,
    levels: ILevel[]
    completed?: boolean,
    selected?: boolean,
}

export interface IFooterMenu {
    id: number,
    title: string,
    links: IFooterMenuLink[]
}
export interface IFooterMenuLink {
    id: number,
    title: string,
    link: string
}

export interface IStore {
    inited: boolean,
    stepInited: boolean,
    ui: IUI,
    dicts: IDict[]
    learning: {
        messages: string[]
    }
    data: {
        selectedDictId: number | null,
        oldDictId: number | null,
        selectedWord: IWord | null,
        selectedDictionaryWord: IWord | null,
        selectedLevelId: number | null,
        completedLevelId: number | null,
        completedDictId: number | null,
        progress: number | null
        learningPassed: boolean
    }
}


class Store implements IStore {
    inited = false
    stepInited = true
    maxWordsPerLevel = 3
    defaultTips = _IS_DEV ? 200 : 16
    defaultAddTips = _IS_DEV ? 10 : 4
    learning = {
        messages: [
            'Перетаскивайте буквы на&nbsp;шаблон сверху экрана, чтобы собрать из&nbsp;них слово и&nbsp;узнать значение',
            'У&nbsp;вас есть 6&nbsp;подсказок. Нажимайте на&nbsp;иконку лампочки, чтобы использовать&nbsp;их. А&nbsp;ещё можно поменять слово на&nbsp;новое, нажав на&nbsp;кнопку замены',
            'Если слово будет собрано полностью верно, то&nbsp;оно загорится зелёным',
        ]
    }
    ui = {
        menuActivity: false,
        gameActivity: false,
        shareActivity: false,
        tipActivity: false,
        disableReset: true,
        canUse: false,
        learningLevel: 1,
        level: {
            current: 0,
            total: 0
        },
        term: {
            current: 0,
            total: 0
        },
        tips: this.defaultTips
    } as IUI
    dicts = [] as IDict[]
    data = {
        selectedDictId: null,
        oldDictId: null,
        selectedWord: null,
        selectedDictionaryWord: null,
        selectedLevelId: null,
        completedLevelId: null,
        completedDictId: null,
        progress: null,
        learningPassed: false,
    } as IStore['data']
    footer = [] as IFooterMenu[]
    constructor() {
        makeAutoObservable(this)
    }

    toggleUIMenu = (type?: boolean) => {
        if (type !== undefined) {
            this.ui.menuActivity = type
        } else {
            this.ui.menuActivity = !this.ui.menuActivity
        }
    }
    toggleUIGameActivity = (type?: boolean) => {
        if (type !== undefined) {
            this.ui.gameActivity = type
        } else {
            this.ui.gameActivity = !this.ui.gameActivity
        }
    }
    toggleUIShareActivity = (type?: boolean) => {
        if (type !== undefined) {
            this.ui.shareActivity = type
        } else {
            this.ui.shareActivity = !this.ui.shareActivity
        }
    }
    toggleUITipActivity = (type?: boolean) => {
        if (type !== undefined) {
            this.ui.tipActivity = type
        } else {
            this.ui.tipActivity = !this.ui.tipActivity
        }
    }

    setNextLearningLevel = () => {
        this.ui.learningLevel = this.ui.learningLevel + 1
        if(this.ui.learningLevel > this.learning.messages.length){
            this.setLearningHide()
        } 
    }

    setLearningHide = () => {
        if(!this.data.learningPassed){
            this.toggleUITipActivity(true)
            console.log('tip RETART')
        }
        this.ui.learningLevel = 0
        this.ui.disableReset = false;
        this.data.learningPassed = true
        AppStore.ui.tips = this.defaultTips
        
    }

    enableUI = (type: boolean) => {
        this.ui.canUse = type
    }

    useTip = () => {
        if (this.ui.tips > 0) {
            let used = GameStore.useTip();
            if (used) {
                this.ui.tips--;
            }
            targetYM('click_podskazka')
        }
        if(AppStore.ui.learningLevel === 2){
            AppStore.setNextLearningLevel()
        }
    }

    ///////////////////////////

    init = (data: any) => {
        this.dicts = data.dicts

        // debugger

        this.dicts.map(dict => {
            dict.completed = false
            dict.selected = false
            dict.levels.sort((a, b) => a.number - b.number)
            dict.levels.map(lvl => {
                lvl.completed = false
                lvl.selected = false
                lvl.pull = []
                lvl.words.map(wrd => {
                    wrd.completed = false
                    // wrd.selected = false
                })
                this.shuffleLevelWords(lvl);
            })
        })

        this.inited = true
    }

    initHeader = () => {
        let terms = { current: 1, total: 0 };
        let levels = { current: 1, total: 0 };

        let dict = this.getSelectedDict()

        levels.total = dict.levels.length
        dict.levels.map(lvl => {
            if (lvl.completed) {
                levels.current++
                if (levels.current > levels.total) {
                    levels.current = levels.total
                }
            }
            if (lvl.id === this.data.selectedLevelId) {
                terms.total = lvl.pull?.length as number
                lvl.pull?.map(id => {
                    let word = this.getWordById(id, lvl)
                    if (word.completed) {
                        terms.current++

                        if (terms.current > terms.total) {
                            terms.current = terms.total
                        }
                    }
                })
            }
        })
        this.ui.level = levels
        this.ui.term = terms
    }

    start = () => {
        console.log('start')
        this.reset()
        let dict = this.getSelectedDict()
        this.data.selectedLevelId = this.getFirstUncomplitedLevel(dict).number

        console.log(this.data)
        this.step()
    }

    step = () => {
        console.log('step')
        let lvl = this.getSelectedLevel()
        console.log(lvl.number)
        this.data.completedLevelId = null
        this.data.completedDictId = null

        let targetWord = {} as IWord

        lvl.pull?.map(id => {
            let word = this.getWordById(id, lvl)
            if (!word.completed) {
                targetWord = word
                this.data.selectedWord = word
            }
        })
        if (targetWord.word) {
            // GameStore.setWord(targetWord)
            GameStore.setWord(targetWord)
            if(this.data.learningPassed){
                this.toggleUITipActivity(true)
            }
        } else {
            this.data.completedLevelId = lvl.number;
            lvl.completed = true
            let dict = this.getSelectedDict()
            let newLevel = this.getFirstUncomplitedLevel(dict)
            AppStore.ui.tips = AppStore.ui.tips + this.defaultAddTips
            if (newLevel) {
                this.data.selectedLevelId = newLevel.number
            } else {
                this.data.completedDictId = dict.id
                dict.completed = true
                this.data.selectedWord = null
            }
        }
        this.initHeader()
        this.stepInited = true
        this.enableUI(true)
    }
    nextStep = () => {
        console.log('nextStep')
        GameStore.reset()
        if (this.stepInited) {
            setTimeout(() => {
                this.step()
            }, 1000)
        }
        this.stepInited = false
        // this.initHeader()
    }

    shuffleWords = () => {
        console.log('shuffleWords')
        let level = this.getSelectedLevel()
        let curWordId = this.data.selectedWord?.id
        let i = 0
        let index = 0
        level.pull?.map(id => {
            if (id === curWordId) {
                index = i
                check(getRandomId())
            }
            i++;
        })

        let word = this.getWordById(level.pull[index], level)
        this.data.selectedWord = word

        console.log(level.pull, index)

        GameStore.reset()
        setTimeout(() => {
            GameStore.setWord(word)
            if(this.data.learningPassed){
                this.toggleUITipActivity(true)
            }
        }, 400)
        targetYM('click_zamena')


        if(AppStore.ui.learningLevel === 2){
            AppStore.setNextLearningLevel()
        }

        function check(newId: number) {
            if (level.pull.includes(newId)) {
                check(getRandomId())
            } else {
                level.pull[index] = newId
            }
        }

        function getRandomId() {
            return level.words[getRandomInRange(0, level.words.length - 1)].id
        }
    }

    reset = () => {
        console.log('test reset', this.data.oldDictId !== this.data.selectedDictId, this.data.oldDictId, this.data.selectedDictId)
        if (this.data.oldDictId !== this.data.selectedDictId) {
            GameStore.reset()
            this.data.completedDictId = null
            this.data.completedLevelId = null
            this.data.selectedLevelId = null
            this.data.selectedWord = null
            this.data.selectedDictionaryWord = null
        }
    }


    setWordComplete = (id: number) => {
        let word = this.getWord(this.data.selectedDictId as number, this.data.selectedLevelId as number, id);
        word.completed = true
        this.data.selectedDictionaryWord = word
        if (this.data.progress !== null) {
            this.data.progress++;
        } else {
            this.data.progress = 1;
        }
        this.save()

        this.enableUI(false)
        this.toggleUITipActivity(false)

        if(AppStore.ui.learningLevel === 3){
            AppStore.setLearningHide()
        }
    }

    ////////////////////////////

    selectDict = (id: number) => {
        this.dicts.map(dict => {
            dict.selected = dict.id === id
            if (dict.id === id) {
                this.data.oldDictId = this.data.selectedDictId
                this.data.selectedDictId = id
            }
        })
    }

    selectDictionaryWord = (wrd: IWord) => {
        let word = this.getWord(wrd.dId, wrd.lId, wrd.id)
        this.data.selectedDictionaryWord = word
    }

    getWord = (dId: number, lId: number, id: number) => {
        let dict = this.dicts.filter(dict => dict.id === dId)[0]
        let level = dict.levels.filter(lvl => lvl.id === lId)[0]
        let word = level.words.filter(wrd => wrd.id === id)[0]
        return word
    }

    shuffleLevelWords = (lvl: ILevel) => {
        let arr: number[] = []
        for (let i = 0; i < this.maxWordsPerLevel; i++) {
            step(getRandomId())
        }
        lvl.pull = arr

        function step(id: number) {
            if (arr.includes(id)) {
                step(getRandomId())
            } else {
                arr.push(id)
            }
        }

        function getRandomId() {
            return lvl.words[getRandomInRange(0, lvl.words.length - 1)].id
        }
    }

    getSelectedDict = () => {
        return this.dicts.filter(dict => dict.selected === true)[0]
    }
    getSelectedLevel = () => {
        let dict = this.getSelectedDict()
        return dict.levels.filter(lvl => lvl.id === this.data.selectedLevelId)[0]
    }

    getLevelCurValue = (lvl: ILevel) => {
        let i = 0
        lvl.words.map(wrd => {
            if (wrd.completed) {
                i++
            }
        })
        return i
    }

    getLevelCurTotal = (lvl: ILevel) => {
        return lvl.pull.length
    }

    getAvailableWordsInDict = (id: number | null) => {
        let i = 0
        if (id == null) return;
        let dict = this.getDictById(id)
        if (!dict) return
        dict.levels.map(lvl => {
            lvl.pull?.map(id => {
                let word = this.getWordById(id, lvl)
                if (!word.completed) {
                    i++;
                }
            })
        })
        return i
    }

    getFirstUncomplitedLevel = (dict: IDict) => {
        return dict.levels.filter(lvl => lvl.completed === false)[0]
    }

    getLevelPullWords = (lvl: ILevel) => {
        let words: IWord[] = []
        lvl.pull.map(id => {
            let word = this.getWordById(id, lvl)
            words.push(word)
        })
        return words.reverse()
    }

    ////////////////////////////

    getDictById = (id: number) => {
        return this.dicts.filter(dict => dict.id === id)[0]
    }
    getLevelById = (id: number, dict: IDict) => {
        return dict.levels.filter(dict => dict.id === id)[0]
    }
    getWordById = (id: number, lvl: ILevel) => {
        return lvl.words.filter(wrd => wrd.id === id)[0]
    }

    ////////////////////////////

    save = () => {
        localStorage.setItem('dicts', JSON.stringify(this.dicts))
        localStorage.setItem('data', JSON.stringify(this.data))
        localStorage.setItem('tips', JSON.stringify(this.ui.tips))
    }

    loadSave = () => {
        this.dicts = JSON.parse(localStorage.getItem('dicts') as string) as IDict[]
        this.data = JSON.parse(localStorage.getItem('data') as string) as IStore['data']
        this.ui.tips = JSON.parse(localStorage.getItem('tips') as string) as number
        this.data.selectedWord = null
        this.inited = true
    }

    checkSave = () => {
        let data = localStorage.getItem('data')
        let progress = null
        if (data) {
            progress = JSON.parse(data).progress
        }
        if (progress !== null) {
            return true
        } else {
            return false
        }
    }

    clearSave = () => {

        localStorage.removeItem('dicts')
        localStorage.removeItem('data')
        localStorage.removeItem('tips')
    }

    setFooter = (footer: IFooterMenu[]) => {
        this.footer = footer
    }
}

const AppStore = new Store()

export default AppStore;
