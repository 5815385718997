
import React, { FC, useEffect, useState } from 'react'
import './styles.scss';
import { Text, Title } from '../../ui/text/text';
import { getCl } from '../../helper';
import { NavLink } from 'react-router-dom';
import Image from '../../ui/image/image';
import Icon from '../../ui/icon/icon';
import { PageContainer } from '../../ui/page/page';
import AppStore from '../../store/appStore';
import GameStore from '../../store/gameStore';
import { observer } from 'mobx-react-lite';
import Menu from '../menu/menu';
import Modal from '../../ui/modal/modal';

interface Props {
}

const Header: FC<Props> = observer((props: Props) => {
  return (
    <header>
      <div className='container'>
        <div className='header__inner'>
          <div className='header__col __logo'>
            <NavLink to={'/'}>
              <Image src='logo.svg' />
            </NavLink>
          </div>
          <div className={`header__col __actions `}>
            <div className={`gameAction__list ${getCl(AppStore.ui.gameActivity, 'active')} ${getCl(AppStore.ui.learningLevel === 2, 'learn_' + AppStore.ui.learningLevel)}`}>
              <div className={`gameAction__item __reset ${getCl(AppStore.ui.disableReset || !AppStore.ui.canUse, 'disabled')}`} onClick={() => AppStore.shuffleWords()}>
                <Icon name="reset" />
              </div>
              <div className={`gameAction__item __tip ${getCl(AppStore.ui.disableReset || AppStore.ui.tips === 0 || !AppStore.ui.canUse, 'disabled')}`} onClick={() => AppStore.useTip()}>
                <Icon name="tip" />
                <span>{AppStore.ui.tips}</span>
              </div>
            </div>
          </div>
          <div className='header__col __info'>
            <div className={`gameInfo__list ${getCl(AppStore.ui.gameActivity, 'active')}`}>
              <div className='gameInfo__item'>
                Уровень <span>{AppStore.ui.level.current}/{AppStore.ui.level.total}</span>
              </div>
              <div className='gameInfo__item'>
                Термин <span>{AppStore.ui.term.current}/{AppStore.ui.term.total}</span>
              </div>
            </div>
            <div className={`burger ${getCl(AppStore.ui.menuActivity, 'active')}`} onClick={() => AppStore.toggleUIMenu()}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        {window.innerWidth > 820 ?
          <Menu inModal={false} />
          :
          <Modal
           isActive={AppStore.ui.menuActivity}
            setActive={(val: boolean) => { AppStore.toggleUIMenu(val) }}
            title='Меню'
            >
            <Menu inModal={true} />
          </Modal>
        }
      </div>
    </header>
  )
})

export default Header;