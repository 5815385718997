import React, { FC, useEffect, useState } from 'react'
import { fetchSvgMarkup, getCl, getRawHtml } from '../../helper'
import './styles.scss';

interface Props {
  name: string,
}

const Icon: FC<Props> = (props: Props) => {
  const [svgMarkup, setSvgMarkup] = useState(null);

  useEffect(() => {
    setSvgMarkup(null);
    fetchSvgMarkup('/assets/icons/icon__' + props.name + '.svg', setSvgMarkup);
  }, [props.name]);

  return <div className={`icon ${getCl(!svgMarkup, 'err')}`} data-err={'/assets/icons/icon__' + props.name + '.svg'} dangerouslySetInnerHTML={getRawHtml(svgMarkup)} />;
}

export default Icon