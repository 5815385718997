
import React, { FC, useEffect, useState } from 'react'
import './styles.scss';
import { observer } from 'mobx-react-lite';
import Image from '../../ui/image/image';
import { IWord } from '../../store/appStore';
import Icon from '../../ui/icon/icon';
import { copyTextToClipboard, getCl, targetYM } from '../../helper';
import { _SITE_URL } from '../../API';

interface Props {
  word: IWord | null
}

const MiniShare: FC<Props> = observer((props: Props) => {
  const [copied, setCopied] = useState<boolean>(false)
  if (props.word === null) return <></>

  const copy = () => {
    if (props.word) {
      copyTextToClipboard(props.word.share, () => {
        setCopied(true)
        targetYM('share_link')
        setTimeout(() => {
          setCopied(false)
        }, 2000)
      })
    }
  }

  // useEffect(() => {
  //   if (copied) {
  //     setTimeout(() => {
  //       setCopied(false)
  //     }, 2000)
  //   }
  // }, [copied])

  return (
    <div className={`miniShare ${getCl(copied, 'copied')}`}>
      <div className='miniShare__box' onClick={copy}>
        <div className='miniShare__link'>{`${_SITE_URL}/share/${props.word.originalId}`}</div>
        <div className='_copy'>
          <Icon name='copy' />
        </div>
        <div className='_copied'>Скопировано!</div>
      </div>
      <div className='miniShare__bot'>
        <div className='miniShare__text'>
          Поделиться в&nbsp;соцсетях:
        </div>
        <div className='miniShare__actions'>
          <a href={`https://vk.com/share.php?url=${_SITE_URL}/share/${props.word.originalId}`} target="_blank" onClick={() => {
            targetYM('share_vk')
          }}>
            <Image src="ref__share_vk.svg" />
          </a>
          <a href={`https://t.me/share/url?url=${_SITE_URL}/share/${props.word.originalId}`} target="_blank" onClick={() => {
            targetYM('share_tg')
          }}>
            <Image src="ref__share_tg.svg" />
          </a>
        </div>
      </div>
    </div>
  )
})

export default MiniShare;