import React, { ReactNode, useEffect, useState } from 'react';
import "./css/styles.scss";
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Index_Page from './pages/indexPage/indexPage';
import Choice_Page from './pages/choicePage/choicePage';
import Game_Page from './pages/gamePage/gamePage';
import { AnimatedRoute } from './ui/page/page';
import Header from './components/header/header';
import Menu from './components/menu/menu';
import Dictionary_Page from './pages/dictionaryPage/dictionaryPage';
import API, { _IS_DEV } from './API';
import GameStore from './store/gameStore';
import AppStore, { IDict, ILevel, IWord } from './store/appStore';
import { getCl, getCookie } from './helper';
import { observer } from 'mobx-react-lite';
import Loader from './ui/loader/loader';
import Share_Page from './pages/sharePage/sharePage';
import Modal from './ui/modal/modal';
import MiniShare from './components/minishare/minishare';
import NotFound_Page from './pages/notFoundPage/notFound';
import Analitycs from './components/analytics/analitycs';
import Rotator from './components/rotator/rotator';
import { Footer } from './ui/footer/footer';

let shareMap__: any = undefined

const App = observer(() => {
  const location = useLocation();
  const [inited, setInited] = useState<boolean>(false);
  const [shareMap, setShareMap] = useState<any>(false);
  const [minHeight, setMinHeight] = useState<number>(window.innerHeight);
  useEffect(() => {
    let isSaved = AppStore.checkSave()
    if (isSaved) {
      AppStore.loadSave()
    } else {
      if (navigator.userAgent !== 'ReactSnap') {
        API.get('/get-words').then(res => {
          console.log(res)
          AppStore.init(res.data)
        }).catch(err => {
          console.log(err)
        })
      }
    }


    API.get('/get-menu').then(res => {
      console.log(res)
      AppStore.setFooter(res.data)
    }).catch(err => {
      console.log(err)
    })

    // if (location.pathname !== '/') return
    // // @ts-ignore
    if (navigator.userAgent === 'ReactSnap') {
      if (shareMap__) return
      API.get('/get-raw-words').then(res => {
        // console.log(res)
        console.log('raw-words: #')
        setShareMap(true)
        shareMap__ = res.data
      }).catch(err => {
        console.log(err)
      })
    }
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);

    }
  }, [])

  useEffect(() => {
    if (location.pathname === '/game') {
      AppStore.toggleUIGameActivity(true)
      //@ts-ignore
      document.querySelector('body').style.touchAction = 'none'
    } else {
      AppStore.toggleUIGameActivity(false)
      //@ts-ignore
      document.querySelector('body').style.touchAction = 'pan-y'
    }
  }, [location.pathname])

  useEffect(() => {
    resize()
    if (AppStore.inited) {
      // resize()
      setTimeout(() => {
        setInited(true)
      }, 400)
    }
  }, [AppStore.inited])

  const resize = () => {
    setMinHeight(window.innerHeight)
  }

  const getShare = () => {
    if (!shareMap__) return <></>
    // @ts-ignore
    return shareMap__.map((wrd: IWord) => {
      return <a href={`/share/${wrd.id}`}></a>
    })
  }
  // const getShareRoutes = () => {
  //   if (!shareMap__) return <></>
  //   // @ts-ignore
  //   return shareMap__.map((wrd: IWord) => {
  //     return <Route path={`/share/${wrd.id}`} />
  //   })
  // }

  return (
    <div id="__site_wrapper" className={`App ${getCl(AppStore.inited, 'inited')}`}>
      <Header />
      <Rotator />
      <Loader loaded={AppStore.inited} />
      <main style={{ minHeight: minHeight + 'px' }}>
        <AnimatedRoute>
          <Routes key={location.pathname} location={location}>
            <Route index element={<Index_Page />} />
            <Route path='share/:id' element={<Share_Page />} />
            <Route path='choice' element={<Choice_Page />} />
            <Route path='game' element={<Game_Page />} />
            <Route path='dictionary' element={<Dictionary_Page />} />
            {/* {getShareRoutes()} */}
            <Route path='*' element={<NotFound_Page />} />
          </Routes>
        </AnimatedRoute>
      </main>
      <Modal
        isActive={AppStore.ui.shareActivity}
        setActive={(val: boolean) => { AppStore.toggleUIShareActivity(val) }}
        title='Поделиться словом'
      >
        <MiniShare word={AppStore.data.selectedWord} />
      </Modal>
      <Analitycs />
      {location.pathname === '/' &&
        <Footer active={location.pathname === '/'} />
      }

      <div style={{ display: 'none' }}>
        {getShare()}
      </div>

    </div>
  );
})

export function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}

export default AppWrapper;
