import React, { FC, useEffect, useState } from 'react'
import './styles.scss';
import { Text, Title } from '../../ui/text/text';
import { getCl } from '../../helper';
import AppStore, { IDict } from '../../store/appStore';
import MainTitle from '../mainTitle/mainTitle';
import Button from '../../ui/button/button';
import { PageContainer } from '../../ui/page/page';
import Image from '../../ui/image/image';
import GameStore from '../../store/gameStore';
import MainTitleNew from '../mainTitleNew/mainTitleNew';

interface Props {
  activity: boolean,
  action: () => void,
}

const GameCompleteLevel: FC<Props> = (props: Props) => {
  const [level, setLevel] = useState<number>(0)

  useEffect(() => {
    if (AppStore.data.completedLevelId) {
      setLevel(AppStore.data.completedLevelId)
    }
  }, [AppStore.data.completedLevelId])

  return (
    <div className={`gameCompleteLevel__container ${getCl(props.activity, 'active')}`}>
      <PageContainer>
        <div className={`gameCompleteLevel`}>
          {/* <MainTitle level={level} /> */}
          <MainTitleNew level={level} />
          <Text type={'t2'} align="center" className='mainText'>
            {level === AppStore.ui.level.total ?
              <>Вы&nbsp;&mdash; настоящий гуру собирания слов, ведь вы&nbsp;успешно прошли все уровни нашего словаря! <br />
                Поделитесь этим великолепным результатом с&nbsp;друзьями или пройдите словарь заново&nbsp;&mdash; там еще много интересных слов!</>
              :
              <>Вы&nbsp;успешно прошли {level} уровень, так держать!<br /> Разгадайте другие не&nbsp;менее интересные слова. Осталось слов: {AppStore.getAvailableWordsInDict(AppStore.data.selectedDictId)}</>
            }
          </Text>
          <div className='actions'>
            {level === AppStore.ui.level.total &&
              <Button type='share' onClick={AppStore.toggleUIShareActivity} />
            }
            <Button type='primary' onClick={props.action} />
          </div>
        </div>
      </PageContainer>

      <div className={`botBg`}>
        <Image src="ref__mainBottomBg.svg" />
      </div>
    </div>
  )
}

export default GameCompleteLevel;