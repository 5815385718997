import React, { FC, JSX, HTMLAttributes, ReactNode } from 'react';
import './styles.scss';
import { getCl, getClR } from '../../helper';

interface Props {
    loaded: boolean
}

export const Loader: FC<Props> = (props: Props) => {
    return (
        <>
            <style>{`
                    
                `}
            </style>
            <div className={`loader ${getCl(props.loaded, 'loaded')}`}>
                <div className='loader__box'>
                    <span />
                    <span />
                    <span />
                    <span />
                </div>
            </div>
        </>
    )
}

export default Loader