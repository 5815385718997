import React, { FC, useEffect, useState } from 'react'
import './styles.scss';
import { Text, Title } from '../../ui/text/text';
import { getCl, getRawHtml } from '../../helper';
import Image from '../../ui/image/image';
import { IDict } from '../../store/appStore';

interface Props extends IDict {
  onClick: () => void,
}

const TopicItem: FC<Props> = (props: Props) => {
  const getWords = () => {
    let i = 0
    props.levels.map(lvl => {
      lvl.words.map(wrd => i++)
    })
    return i;
  }
  return (
    <div className={`topicItem ${getCl(props.selected, 'selected')} ${getCl(props.completed, 'completed')}`} onClick={props.onClick}>
      {props.title &&
        <Title type="h3" className="topicItem__title">
          {props.title}
        </Title>
      }
      <Text type="t1" className="topicItem__levels">
        Слов: {getWords()} &nbsp;&nbsp;&nbsp; Уровней: {props.levels.length}
      </Text>
      {props.description &&
        <Text type="t2" className="topicItem__text">
          <span dangerouslySetInnerHTML={getRawHtml(props.description)} />
        </Text>
      }
      {props.completed &&
        <Image src="ref__check.svg" />
      }
    </div>
  )
}

export default TopicItem;