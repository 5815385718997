
import React, { FC, useEffect, useState } from 'react'
import './styles.scss';
import { Text, Title } from '../../ui/text/text';
import { NavLink } from 'react-router-dom';
import Icon from '../../ui/icon/icon';
import AppStore from '../../store/appStore';
import { getCl, targetYM } from '../../helper';
import { observer } from 'mobx-react-lite';

interface Props {
  inModal: boolean
}

const Menu: FC<Props> = observer((props: Props) => {
  return (
    <aside className={`menu ${getCl(AppStore.ui.menuActivity, 'active')} ${getCl(props.inModal, 'inModal')}`}>
      <nav className='menu__list'>
        <NavLink className={'menu__item'} to={'/game'} onClick={() => {
          targetYM('click_menu_vigru')
          AppStore.toggleUIMenu(false)
        }}>
          <Icon name="backToGame" />
          <Title type={'h4'}>
            В игру
          </Title>
        </NavLink>
        <NavLink className={'menu__item'} to={'/dictionary'} onClick={() => {
          targetYM('click_menu_slovar')
          AppStore.toggleUIMenu(false)
        }}>
          <Icon name="dictionary" />
          <Title type={'h4'}>
            В Словарь
          </Title>
        </NavLink>
        <NavLink className={'menu__item'} to={'/'} onClick={() => {
          targetYM('click_menu_glavnaya')
          AppStore.toggleUIMenu(false)
        }}>
          <Icon name="home" />
          <Title type={'h4'}>
            На главную
          </Title>
        </NavLink>
      </nav>
    </aside>
  )
})

export default Menu;