import { FC, ReactNode, useEffect, useState } from 'react';
import './styles.scss';
import { getCl, getClR } from '../../helper';
import { Outlet, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

interface PageProps {
    children: ReactNode
    className?: string,
}

export const Page: FC<PageProps> = (props: PageProps) => {

    const [loaded, setLoaded] = useState(false)
    const [overloaded, setOverloaded] = useState(false)
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true)
            // setTimeout(() => {
            //     setOverloaded(true)
            // }, 400)
        }, 100)

        window.addEventListener('changeRoute', onUnmount)
        return () => {
            window.removeEventListener('changeRoute', onUnmount)
        }
    }, [])

    function onUnmount() {
        console.log('UNMOUNT', location.pathname, props.className, loaded)
        setLoaded(false)
    }

    useEffect(() => {
        if (loaded) {
        }
    }, [location.pathname])

    return (
        <div className={`page ${getClR(props.className)} ${getCl(loaded, 'loaded')} ${getCl(overloaded, 'overloaded')}`}>
            {props.children}
        </div>
    )
}

interface PageContainerProps {
    children: ReactNode
    className?: string,
}

export const PageContainer: FC<PageContainerProps> = (props: PageContainerProps) => {
    return (
        <div className={`container ${getClR(props.className)}`}>
            {props.children}
        </div>
    )
}

interface AnimatedRouteProps {
    children: ReactNode,
}

export const AnimatedRoute: FC<AnimatedRouteProps> = (props: AnimatedRouteProps) => {
    const location = useLocation();
    const [page, setPage] = useState<any>();
    // const [oldPage, setOldPage] = useState<any>();
    const [pageState, setPageState] = useState<string>('');

    useEffect(() => {
        setPage(props.children)
    }, [])

    useEffect(() => {
        setPageState('in')
        window.dispatchEvent(new CustomEvent('changeRoute'))
        setTimeout(() => {
            setPage(props.children)
            setPageState('done')
        }, 600)
    }, [location.pathname])

    return (
        <div className={`pages ${getCl(true, pageState)}`}>
            {page}
        </div>
    )
};