import React, { FC, useEffect, useState } from 'react'
import { Page, PageContainer } from '../../ui/page/page';
import './styles.scss';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Dictionary from '../../components/dictionary/dictionary';
import AppStore from '../../store/appStore';

interface Props {
}

const Dictionary_Page: FC<Props> = observer((props: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if(!AppStore.data.selectedDictId){
      navigate('/choice')
    }
  }, [])

  return (
    <Page className={`dictionary`}>
      <PageContainer>
        <Dictionary />
      </PageContainer>
    </Page>
  )
})

export default Dictionary_Page