
import React, { FC, useEffect, useState } from 'react'
import './styles.scss';
import { getCl, getRawHtml } from '../../helper';
import Icon from '../../ui/icon/icon';
import { Text, Title } from '../../ui/text/text';
import Button from '../../ui/button/button';
import AppStore from '../../store/appStore';
import { observer } from 'mobx-react-lite';

interface Props {
    icon: string,
    isActive: boolean,
    setActive: (v: boolean) => void,
}

const GameTip: FC<Props> = observer((props: Props) => {
    const [visible, setVisible] = useState<boolean>(true)
    const [curValue, setCurValue] = useState<number>(0)
    const [totalValue, setTotalValue] = useState<number>(0)
    const [text, setText] = useState<string>('')

    useEffect(() => {
        setVisible(false)
        if (!AppStore.data.learningPassed) {
            setTimeout(() => {
                setCurValue(AppStore.ui.learningLevel)
                setTotalValue(AppStore.learning.messages.length)
                setText(AppStore.learning.messages[AppStore.ui.learningLevel - 1])
                setVisible(true)
            }, 200)
        }
    }, [AppStore.ui.learningLevel])

    const next = () => {
        AppStore.setNextLearningLevel()
    }

    return (
        <div className={`gameTip ${getCl(props.isActive, 'active')} ${getCl(visible, 'visible')}`}>
            <div className='gameTip__close' onClick={() => props.setActive(false)}>
                Пропустить обучение
                <Icon name="close" />
            </div>
            <div className='gameTip__body'>
                <div className='gameTip__scroller'>
                    <div className='gameTip__inner'>
                        <div className='gameTip__icon'>
                            <Icon name={props.icon} />
                        </div>
                        <div className='gameTip__counter'>
                            {curValue}/{totalValue}
                        </div>
                        <div className='gameTip__text' dangerouslySetInnerHTML={getRawHtml(text)}>
                        </div>
                    </div>
                </div>
            </div>
            <div className='gameTip__actions'>
                <Button type={"secondary"} onClick={next}>
                    <Title type='h4'>
                        {curValue === totalValue ? 'Завершить' : 'Далее'}
                    </Title>
                </Button>
            </div>
        </div>
    )
})

export default GameTip;