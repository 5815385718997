import React, { FC, useEffect, useState } from 'react'
import Image from '../../ui/image/image';
import './styles.scss';
import LMainTitle from "./lotties/first_desk.json"
import LMainTitleMob from "./lotties/first_mobile.json"
import LLevel_1 from "./lotties/level_1.json"
import LLevel_2 from "./lotties/level_2.json"
import LLevel_3 from "./lotties/level_3.json"
import LLevel_4 from "./lotties/final.json"
import Lottie from "react-lottie";
import { getCl } from '../../helper';
interface Props {
  level?: number,
}

const MainTitleNew: FC<Props> = (props: Props) => {
  let animData: any = LMainTitle
  // if(props.level){
  let lvl = props.level
  // let lvl = 1
  switch (lvl) {
    case 1:
      animData = LLevel_1;
      break;
    case 2:
      animData = LLevel_2;
      break;
    case 3:
      animData = LLevel_3;
      break;
    case 4:
      animData = LLevel_4;
      break;
  }
  if(!props.level && window.innerWidth < 821){
    animData = LMainTitleMob
  }
  // }
  return (
    <div className={`mainTitle ${getCl(!!props.level, 'level')}`} data-level={props.level}>
      <div className='mainTitle__lottie'>
        <Lottie
          options={{
            animationData: animData,
            autoplay: true,
            loop: false,
          }}
          isClickToPauseDisabled={true}
        />
      </div>
    </div>
  )
}

export default MainTitleNew