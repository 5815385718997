import React, { FC, useEffect, useState } from 'react'
import { Page, PageContainer } from '../../ui/page/page';
import Image from '../../ui/image/image';
import { Text, Title } from '../../ui/text/text';
import './styles.scss';
import { observer } from 'mobx-react-lite';
import TopicItem from '../../components/choiceItem/choiceItem';
import Button from '../../ui/button/button';
import { useNavigate, useParams } from 'react-router-dom';
import { getCl } from '../../helper';
import GameStore from '../../store/gameStore';
import AppStore, { IWord } from '../../store/appStore';
import Lottie from 'react-lottie';
import LottieData from "../../components/mainTitleNew/lotties/first_mobile.json";
import GameMess from '../../components/gameBox/gameMess';
import { Helmet } from 'react-helmet';
import API from '../../API';
// import Helmet from "react-helmet"

interface Props {
}

const Share_Page: FC<Props> = observer((props: Props) => {
  const navigate = useNavigate();
  const { id } = useParams()
  const [word, setWord] = useState<IWord>({} as IWord)

  useEffect(() => {
    API.get('/get-word?id=' + id).then(res => {
      console.log(res.data.word)
      setWord(res.data)
    })
  }, [])

  return (
    <>
      {word.word &&
        <Helmet>
          <title>{word.word} – АЧБД Словарь Далее</title>
          <meta name="description" content={`${word.description?.split('.')[0]}. – соберите все слова`} />
        </Helmet>
      }
      <Page className={`share`}>
        {/* {id} */}
        <div className='shareBox'>
          <div className='shareBox__row'>
            <div className='shareBox__col'>
              <div className='shareBox__lottieBox'>
                <div className='shareBox__lottie'>
                  <Lottie
                    options={{
                      animationData: LottieData,
                      loop: false,
                      autoplay: true
                    }}
                    isClickToPauseDisabled={true}
                  />
                </div>
              </div>
              <div className='shareBox__image'>
                <img src="/assets/ref__share_bg.svg" />
              </div>
            </div>
            <div className='shareBox__col'>
              <div className='shareBox__gameMess'>
                <GameMess
                  title={word.word}
                  text={word.description}
                  action={() => { navigate('/'); }}
                  icon={'tip_hand'}
                  download={word.link}
                  isActive={true}
                  setActive={() => { }} />
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  )
})

export default Share_Page