import { getRandomInRange } from "./helper";

export  function getRandomPositions(arr: any, box: any, el: any, blur: number = 0, acc: number = 0): any {
  let x = getRandomInRange(0, (box.width - el.width));
  let y = Math.random() * (box.height - el.height);

  let law = true;
  arr.map((e: any) => {
    let startX = x;
    let endX = x + el.width;
    let oldStartX = e.x - blur;
    let oldEndX = e.x + el.width + blur;
    let startY = y;
    let endY = y + el.height;
    let oldStartY = e.y - blur;
    let oldEndY = e.y + el.height + blur;
    if ((
      (startX >= oldStartX && startX <= oldEndX) ||
      (endX >= oldStartX && endX <= oldEndX)
    ) && (
        (startY >= oldStartY && startY <= oldEndY) ||
        (endY >= oldStartY && endY <= oldEndY)
      )) {
      law = false;
    }
  })
  acc++;
  if (!law && acc < 5) {
    return getRandomPositions(arr, box, el, acc);
  } else {
    arr.push({ x, y })
    const pos: { x: number, y: number } = { x, y }
    return pos
  }
}