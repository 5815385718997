import React, { FC, useEffect, useState } from 'react'
import { Page, PageContainer } from '../../ui/page/page';
import './styles.scss';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import GameBox from '../../components/gameBox/gameBox';
import AppStore from '../../store/appStore';
import GameCompleteLevel from '../../components/gameCompleteLevel/gameCompleteLevel';

interface Props {
}

const Game_Page: FC<Props> = observer((props: Props) => {
  const navigate = useNavigate();
  const [levelActivity, setLevelActivity] = useState<boolean>(false)

  useEffect(() => {
    console.log(AppStore.data)
    if(!AppStore.data.selectedDictId){
      navigate('/choice')
    } else {
      if(!AppStore.data.selectedWord){
        AppStore.start()
      }
    }
  },[])
  useEffect(() => {
    if (AppStore.data.completedLevelId) {
      setLevelActivity(true)
    } else {
      setLevelActivity(false)
    }
  }, [AppStore.data.completedLevelId])

  return (
    <Page className={`game`}>
      <PageContainer>
        <GameBox activity={!levelActivity} />
      </PageContainer>
      <GameCompleteLevel
        activity={levelActivity}
        action={() => { 
          if(AppStore.data.completedDictId){
            navigate('/choice')
          } else {
            AppStore.step();
          }
        }}
      />
    </Page>
  )
})

export default Game_Page