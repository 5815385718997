
import React, { FC, useEffect, useState } from 'react'
import './styles.scss';
import { _IS_DEV } from '../../API';
import { getCl } from '../../helper';
import Image from '../../ui/image/image';
import { Title } from '../../ui/text/text';

interface Props {
}

const Rotator: FC<Props> = (props: Props) => {
    const [active, setActive] = useState<boolean>(false)
    
    useEffect(() => {
        let portrait = window.matchMedia("(orientation: portrait)");

        portrait.addEventListener("change", resize)
        return () => {
            portrait.removeEventListener("change", resize)
        }
    }, [])

    const resize = (e: MediaQueryListEvent) => {
        setActive(!e.matches)
    }
    // console.log(_IS_DEV, process.env.REACT_APP_IS_DEV)
    return (
        <div className={`rotator ${getCl(active, 'active')}`}>
            <div className='rotator__image'>
                <Image src='ref__turnPhone.svg' />
            </div>
            <div className='rotator__title'>
                <Title type='h3'>Поверните своё устройство вертикально</Title>
            </div>
        </div>
    )
}

export default Rotator;