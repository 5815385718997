import React, { FC, useEffect, useState } from 'react'
import { Page, PageContainer } from '../../ui/page/page';
import Image from '../../ui/image/image';
import './styles.scss';
import { Text, Title } from '../../ui/text/text';
import Button from '../../ui/button/button';
import { useLocation, useNavigate } from 'react-router-dom';
import MainTitle from '../../components/mainTitle/mainTitle';
import { getCl, getRawHtml, targetYM } from '../../helper';
import AppStore from '../../store/appStore';
import Icon from '../../ui/icon/icon';
import MainTitleNew from '../../components/mainTitleNew/mainTitleNew';
import Modal from '../../ui/modal/modal';

interface Props {
}

const Index_Page: FC<Props> = (props: Props) => {
  const navigate = useNavigate()
  const [resetModal, setResetModal] = useState<boolean>(false);

  const resetGame = () => {
    targetYM('click_glavnaya_zanovo')
    AppStore.clearSave()
    navigate('/')
    window.location.reload()
  }

  return (
    <Page className={`index`}>
      <PageContainer>
        {/* <Lottie animationData={} /> */}
        <MainTitleNew />
        <Text type={'t2'} align="center" className='mainText'>
          Игра, которая расширяет словарный запас. <br />Поможет узнать новые слова (даже если они старые) и&nbsp;лучше понять, с&nbsp;чем мы&nbsp;придем в&nbsp;будущее
        </Text>
        <div className='actions'>
          {AppStore.data.progress ?
            <>
              <Button type='secondary' classList='__reset' onClick={() => setResetModal(true)}>
                <Icon name="reset" />
                <Title type={'h4'}>Начать заново</Title>
              </Button>
              <Button type='secondary' classList='__continue' onClick={() => {
                targetYM('click_glavnaya_prodolzhit')
                navigate('/game')
              }}>
                <Title type={'h3'}>продолжить игру</Title>
              </Button>
              <Modal
                isActive={resetModal}
                title='Начать заново?'
                setActive={setResetModal}
              >
                <Text type='t2'>
                  <span dangerouslySetInnerHTML={getRawHtml('Вы&nbsp;уверены, что хотите обнулить результаты?')}></span>
                </Text>
                <Button type='secondary' classList='__reset' onClick={resetGame}>
                  <Title type={'h4'}>Да</Title>
                </Button>
              </Modal>
            </>
            :
            <Button type='primary' onClick={() => {
              targetYM('click_start_dalee')
              navigate('/choice')
            }} />
          }
        </div>
      </PageContainer>
      <div className='botBg'>
        <Image src="ref__mainBottomBg.svg" />
      </div>
    </Page>
  )
}

export default Index_Page