import React from 'react';
import ReactDOM, { hydrateRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { hydrate, render } from "react-dom";

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   // <React.StrictMode>
//     <App />
//   // {/* </React.StrictMode> */}
// );

const root = document.getElementById("root") as Element

// if (root?.hasChildNodes()) {
//   hydrateRoot(root as Element, <App />)
// } else {
  const _root = ReactDOM.createRoot(root);
  _root.render(<App />);
// }
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
